import React, { useState,useEffect } from 'react';
import { setTitle } from '../helpers/MetaTag';
import MainBanner from "../components/sections/MainBanner";
import AboutUs from "../components/sections/AboutUs";
import PlanPricing from "../components/sections/PlanPricing";
import Faq from "../components/sections/Faq";
import ContactUs from "../components/sections/ContactUs";
import ContactForm from "../components/partials/ContactForm";

import BannerImage from "../assets/images/banner-01.jpg";
import AboutImage from "../assets/images/aboutus.jpg";
import FaqImage from "../assets/images/faq.jpg";
import PlayStore from "../assets/images/play-store.png";
import AppStore from "../assets/images/app-store.png";
import Modal from '../components/elements/modal/Modal';
import Signup from '../components/partials/Signup';

import { apiService } from '../services/api';
const Home = () => {
  setTitle('Ajiji | Unlock your full potential');
  const [isOpenSignUp, setIsOpenSignUp] = useState(false);
  const [faqLoader,setFaqLoader] = useState(false);
  const [aboutusLoader,setAboutusLoader] = useState(false);
  const [faq, setFaq] = useState([]);
  const [abouts, setAbouts] = useState({});
  const [settings,setSettings] = useState({});
  const [banners,setBanner] = useState([]);
  //const [pricingInfo,setPricingInfo] = useState({});
  const [plans,setPlans] = useState([]);

  useEffect(() => {
    async function faqData() {
      setFaqLoader(true);
      try {
        const result = await apiService("faq/list?status=1",'get');
        const result2 = await apiService("page/details/faq",'get');
        if (result.status === 200) {
          setFaq({
            title: (result2?.data?.title)?result2?.data?.title:'',
            image: (result2?.data?.image)?result2?.data?.image:FaqImage,
            description : (result2?.data?.description)?result2?.data?.description:'',
            faqs: result.data.map((item,index)=>{
              return {
                _id : item?._id,
                title : item?.question,
                description:item?.answer,
                isOpen:(index === 0)?true:false,
              }
            }),
          });
          setFaqLoader(false);
        } else {
          console.log("faq list message", result.message);
          setFaqLoader(false);
        }
      } catch (error) {
        console.log("Error faq list catch", error.message);
        setFaqLoader(false);
      }
    }
    faqData();

    async function aboutData() {
      setAboutusLoader(true);
      try {        
        const result = await apiService("page/details/about-us",'get');
        if (result.status === 200) {
          setAbouts({
            title: "About <span class='text-pistachio-500 font-bold uppercase'>Ajiji</span>",
            image: (result?.data?.image)?result?.data?.image:AboutImage,
            description : result?.data?.description
          });
          setAboutusLoader(false);
        } else {
          console.log("faq list message", result.message);
          setAboutusLoader(false);
        }
      } catch (error) {
        console.log("Error faq list catch", error.message);
        setAboutusLoader(false);
      }
    }
    aboutData();

    // async function pricingData() {
    //   try {        
    //     const result = await apiService("page/details/pricing",'get');
    //     if (result.status === 200) {
    //       setPricingInfo({
    //         title: (result?.data?.title)?result?.data?.title:'',            
    //         description : (result?.data?.description)?result?.data?.description:''
    //       });
    //     } else {
    //       console.log("faq list message", result.message);
    //     }
    //   } catch (error) {
    //     console.log("Error faq list catch", error.message);
    //   }
    // }
    // pricingData();    

    async function settingsData() {
      try {        
        const result = await apiService("setting/detail",'get');
        if (result.status === 200) {
          setSettings(result?.data);
        } else {
          console.log("settings details message", result.message);
        }
      } catch (error) {
        console.log("Error settings details catch", error.message);
      }
    }
    settingsData();

    async function bannerData() {
      try {        
        const result = await apiService("banner/list",'get');
        if (result.status === 200) {
          setBanner({
            image: (result?.data && result?.data.length>0)?result?.data[0]?.image:BannerImage,
            title: (result?.data && result?.data.length>0)?result?.data[0]?.title:'',
            subtitle: (result?.data && result?.data.length>0)?result?.data[0]?.subtitle:'',
            description: (result?.data && result?.data.length>0)?result?.data[0]?.description:'',
            actionLink: () => { setIsOpenSignUp(true) },
            actionLabel:"Get Started"
          });
        } else {
          console.log("Banner list message", result.message);
        }
      } catch (error) {
        console.log("Error banner list catch", error.message);
      }
    }
    bannerData();    

    async function planData() {
      try {        
        const result = await apiService("plan/list",'get');
        const result2 = await apiService("page/details/pricing",'get');
        if (result.status === 200) {
          setPlans({
            title: result2?.data?.title,
            description: result2?.data?.description,
            pricing : result.data.map((item,index)=>{
              return {
                _id: item?._id,
                name: item?.name,
                amount: item?.price,
                terms: item?.duration,
                tag: item?.tag,//"Recomended",
                click: () => { setIsOpenSignUp(true)},
                features: item?.features
              }
            })
          });
        } else {
          console.log("Plan list message", result.message);
        }
      } catch (error) {
        console.log("Error plan list catch", error.message);
      }
    }
    planData();
  },[]) 

  // const bannerData = {
  //   image: BannerImage,
  //   title: "Unlock your full <span class='text-pistachio-500 font-bold'>potential</span>",
  //   subtitle: "Summer Summit",
  //   description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
  //   actionLink: "/",
  //   actionLabel: "Get Started"
  // }  

  // const pricingData = {
  //   title: pricingInfo?.title,
  //   description: pricingInfo?.description,
  //   pricing: [
  //     {
  //       _id: 1,
  //       name: "Basic",
  //       amount: 250,
  //       terms: "mo",
  //       tag: "",
  //       click: () => { },
  //       features: [
  //         { name: "Personal Access", availability: true },
  //         { name: "Unlimited overview", availability: true },
  //         { name: "Limited task creation and edit", availability: true },
  //         { name: "Direct messeging to the coach", availability: true },
  //         { name: "Upto 5 areas of interest", availability: true },
  //         { name: "Self-Learning system", availability: false },
  //         { name: "Google task integration", availability: false },
  //       ]
  //     },
  //     {
  //       _id: 2,
  //       name: "Standard",
  //       amount: 500,
  //       terms: "mo",
  //       tag: "Recomended",
  //       click: () => { },
  //       features: [
  //         { name: "Personal Access", availability: true },
  //         { name: "Unlimited overview", availability: true },
  //         { name: "Unlimited task creation and edit", availability: true },
  //         { name: "Direct messeging to the coach", availability: true },
  //         { name: "Upto 10 areas of interest", availability: true },
  //         { name: "Self-Learning system", availability: true },
  //         { name: "Google task integration", availability: true },
  //       ]
  //     },
  //     {
  //       _id: 3,
  //       name: "Premium",
  //       amount: 750,
  //       terms: "mo",
  //       tag: "",
  //       click: () => { },
  //       features: [
  //         { name: "Personal Access", availability: true },
  //         { name: "Unlimited overview", availability: true },
  //         { name: "Unlimited task creation and edit", availability: true },
  //         { name: "Direct messeging to the coach", availability: true },
  //         { name: "All areas of interest", availability: true },
  //         { name: "Self-Learning system", availability: true },
  //         { name: "Google task integration", availability: true },
  //       ]
  //     },
  //   ]
  // }  

  const handleChange = (data) => {
    setFaq({
      ...faq,
      faqs: faq?.faqs.map((fq) => {
        if (data?._id === fq?._id) {
          return { ...fq, isOpen: !fq.isOpen }
        } else {
          return { ...fq, isOpen: (data?.isOpen) ? fq.isOpen : false }
        }
      })
    })
  }

  const contactUsData = {
    title: "Contact Us",
    secondaryTitle: "Chat to our friendly team",
    subtitle: "We’d love to hear from you. Please fill out this form or shoot us an email.",
    formTitle: "Feel free to contact us.",
    contactForm: <ContactForm />,
    contact: [      
      {
        icon: "fa-regular fa-envelope",
        name: "Email",
        description: "Our friendly team is here to help.",
        type: "email",
        link: "",
        data: settings?.email
      },
      {
        icon: "fa-regular fa-phone",
        name: "Phone",
        description: "Mon-Fri from 8am to 5pm.",
        type: "phone",
        link: "",
        data: settings?.phone
      },
    ],
    socialMenuTitle: "Follow us on",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: settings?.social?.facebook,
        icon: "fa-facebook-f"
      },
      {
        _id: 2,
        name: "Instagram",
        link: settings?.social?.instagram,
        icon: "fa-instagram"
      },
      {
        _id: 3,
        name: "Twitter",
        link: settings?.social?.twitter,
        icon: "fa-twitter"
      },
    ],
    appDownload: {
      title: "App Download",
      menu: [
        { 
          _id: 1, 
          name: "Play Store", 
          link: settings?.social?.playstore, 
          image: PlayStore 
        },
        { 
          _id: 2, 
          name: "App Store", 
          link: settings?.social?.appstore, 
          image: AppStore 
        },
      ],
    },
  }  

  return (
    <>
      <MainBanner data={banners} />
      <AboutUs data={abouts} imagePosition={"left"} loader={aboutusLoader} />
      <PlanPricing data={plans} />
      <Faq data={faq} handleChange={handleChange} loader={faqLoader} />
      <ContactUs data={contactUsData} settings={settings}/>
      <Modal
        isOpen={isOpenSignUp}
        onCancel={() => setIsOpenSignUp(false)}
        title={""}
        secondaryTitle={""}
        showClose={false}
      >
      <Signup 
      click={() => { 
        setIsOpenSignUp(false); 
        //setIsOpenPricing(true) 
      }}
      onClose={() => setIsOpenSignUp(false) }
      />
      </Modal>
    </>
  );
};

export default Home;