import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";

const SearchWithDropdown = ({
  label,
  labelClasses,
  placeholder,
  divClasses,
  inputClasses,
  iconClasses,
  iconPosition = "left",
  icon = "fa-regular fa-magnifying-glass",
  xPlacement,
  dropdownClass,
  dropdownData,
  getSelectedValue = () => {}, 
}) => {
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState('');
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const filteredData =
    query === ''
      ? dropdownData
      : dropdownData.filter((person) =>
        person.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  return (
    <>
      <div className="relative">
        {label &&
          <div className={classNames("text-sm font-medium text-slate-500 mb-1", labelClasses)}>{label}</div>
        }
        <Combobox value={selected} onChange={(e) => {setSelected(e); getSelectedValue(e)}} as={'div'} className="relative">
          <div ref={setTargetElement} className={classNames("relative flex h-10 w-full bg-slate-50 rounded-md border border-slate-200 overflow-hidden", divClasses)}>
            {iconPosition === "left" &&
              <Combobox.Button className={classNames("w-10 h-10 min-w-[40px] flex items-center justify-center text-slate-400 ", iconClasses)}>
                <i className={classNames("fa-fw", icon)}></i>
              </Combobox.Button>
            }
            <Combobox.Input
              displayValue={(person) => person.name}
              onChange={(event) => setQuery(event.target.value)}
              className={classNames("!border-0 w-full !ring-0 bg-transparent text-slate-600 text-sm p-0 pr-3", inputClasses)}
            />
            {iconPosition === "right" &&
              <Combobox.Button className={classNames("w-10 h-10 min-w-[40px] flex items-center justify-center text-slate-400 ", iconClasses)}>
                <i className={classNames("fa-fw", icon)}></i>
              </Combobox.Button>
            }
          </div>
          <Transition
            as={Fragment}
            leave="transition duration-75" leaveFrom="opacity-100" leaveTo="opacity-0" className="z-50 w-full"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => { setPopperElement(null); setQuery(''); }}
            ref={popperElRef}
            style={styles.popper}
            {...attributes.popper}
          >
            <Combobox.Options className={classNames("w-full origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0 overflow-auto scroll-smooth scrollbar", dropdownClass)}>
              <div className="py-1 max-h-60 divide-y divide-slate-100 ">
                {filteredData.length > 0 && filteredData.map((item, index) => (
                  <Combobox.Option
                    key={index}
                    className={({ active }) => classNames(
                      "relative select-none py-2 pl-8 pr-2 text-xs leading-tight cursor-pointer transition-all duration-200",
                      active ? 'bg-pistachio-50 text-slate-700' : 'text-slate-500'
                    )}
                    value={item}>
                    {({ selected }) => (
                      <>
                        <div className={classNames("block truncate", selected ? 'font-medium' : 'font-normal')}>{item.name}</div>
                        {selected &&
                          <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-pistachio-500 text-base">
                            <i className="fa-regular fa-fw fa-check"></i>
                          </div>
                        }
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </div>
            </Combobox.Options>
          </Transition>
        </Combobox>
      </div>
    </>
  );
};

export default SearchWithDropdown;