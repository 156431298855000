import React from 'react';
import { classNames } from "../../helpers/classNames";
import Image from "../elements/Image";

const AboutUs = ({ data, imagePosition }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-20 bg-white" id="about">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className={classNames("w-full flex flex-col sm:flex-row xl:items-center gap-10 sm:gap-5 lg:gap-0", imagePosition !== 'left' ? "flex-row-reverse" : "")}>
            <div className="w-full sm:w-6/12">
              <div className="lg:w-11/12 overflow-hidden rounded-3xl shadow-2xl flex">
                <Image src={data.image} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'} />
              </div>
            </div>
            <div className="w-full sm:w-6/12">
              <div className="space-y-8">
                <h2 className="font-MPlusRounded1c text-3xl sm:text-4xl xl:text-5xl text-cyprus-500" dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                <div className="text-sm xl:text-base text-slate-600 space-y-4" dangerouslySetInnerHTML={{ __html: data.description }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;