import React from 'react';
import Image from "../elements/Image";
import FaqItem from "../items/FaqItem";
import FaqSkeleton from '../loader/FaqSkeleton';

const Faq = ({ data, handleChange = () => { },loader }) => {

  return (
    <>
      {
        loader?<FaqSkeleton/>:
        <section className="relative overflow-hidden w-full py-20 bg-slate-100 z-0 min-h-[800px]" id="faq">
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
            <div className="grid grid-cols-5 gap-10">
              <div className="col-span-2">
                <div className="relative h-[500px] rounded-3xl shadow-2xl overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full z-[-1]">
                    <Image src={data.image} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover object-center'} />
                  </div>
                  <div className="absolute top-0 left-0 w-full h-full z-0 bg-cyprus-500 opacity-60"></div>
                  <div className="absolute top-0 left-0 w-full h-full z-[1] py-10 px-10 space-y-4 flex flex-col justify-end">
                    <h2 className="font-MPlusRounded1c text-3xl sm:text-4xl xl:text-5xl text-white" dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                    <div className="text-sm xl:text-base text-white/75 space-y-4" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                  </div>
                </div>
              </div>
              <div className="col-span-3">
                {data.faqs?.length > 0 &&
                  <div className="space-y-4">
                    {data.faqs?.map((item) => (
                      <FaqItem key={item._id} data={item} handleChange={handleChange} />
                    ))}
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      }      
    </>
  );
};

export default Faq;