import React, { useState,useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ScrollSpy from "react-ui-scrollspy";

import DefaultHeader from '../shared/DefaultHeader';
import DefaultFooter from "../shared/DefaultFooter";
import Modal from "../components/elements/modal/Modal";
import Signup from "../components/partials/Signup";

import Logo from "../assets/images/logo.svg";
import LogoDark from "../assets/images/logo_dark.svg";
import PlayStore from "../assets/images/play-store.png";
import AppStore from "../assets/images/app-store.png";
import PlanPricingTable from "../components/partials/PlanPricingTable";
import { apiService } from '../services/api';


const DefaultLayout = () => {
  const [isOpenSignUp, setIsOpenSignUp] = useState(false);
  const [isOpenPricing, setIsOpenPricing] = useState(false);
  const [settings,setSettings] = useState({});

  const menuData = {
    logo: LogoDark,
    menu: [
      { _id: 1, name: "Home", link: "banner", },
      { _id: 2, name: "About Us", link: "about", },
      { _id: 3, name: "Plans & Pricing", link: "plans", },
      { _id: 4, name: "FAQ's", link: "faq", },
      { _id: 5, name: "Contact Us", link: "contact", }
    ],
    options: {
      actionLabel: "Signup",
      actionClick: () => setIsOpenSignUp(true)
    }
  }

  const footerData = {
    logo: Logo,
    footerMenu: {
      title: "Quick Links",
      menu: [
        { _id: 1, name: "Home", link: "#banner", },
        { _id: 2, name: "About Us", link: "#about", },
        { _id: 3, name: "Plans & Pricing", link: "#plans", },
        { _id: 4, name: "FAQ's", link: "#faq", },
        { _id: 5, name: "Contact Us", link: "#contact", }
      ],
    },
    contact: {
      title: "Contact Us",
      subdata: [
        {
          _id: 1,
          label: "Email",
          data: settings?.email,
          icon: "fa-envelope",
          type: "mailto"
        },
        {
          _id: 2,
          label: "Contact No.",
          data: settings?.phone,
          icon: "fa-phone-volume",
          type: "tel"
        },
        {
          _id: 3,
          label: "Address",
          data: settings?.address,
          icon: "fa-location-dot",
          type: "address"
        },
      ]
    },
    footerDownload: {
      title: "App Download",
      menu: [
        { 
          _id: 1, 
          name: "Play Store", 
          link: settings?.social?.playstore, 
          image: PlayStore 
        },
        { 
          _id: 2, 
          name: "App Store", 
          link: settings?.social?.appstore, 
          image: AppStore 
        },
      ],
    },
    socialMenuTitle: "Reach us at",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: settings?.social?.facebook,
        icon: "fa-facebook-f"
      },
      {
        _id: 2,
        name: "Instagram",
        link: settings?.social?.instagram,
        icon: "fa-instagram"
      },
      {
        _id: 3,
        name: "Twitter",
        link: settings?.social?.twitter,
        icon: "fa-twitter"
      },
    ],
    copyright: settings?.copyright
  }

  const pricing = [
    {
      _id: 1,
      name: "Basic",
      amount: 250,
      terms: "mo",
      tag: "",
      click: () => { },
      features: [
        { name: "Personal Access", availability: true },
        { name: "Unlimited overview", availability: true },
        { name: "Limited task creation and edit", availability: true },
        { name: "Direct messeging to the coach", availability: true },
        { name: "Upto 5 areas of interest", availability: true },
        { name: "Self-Learning system", availability: false },
        { name: "Google task integration", availability: false },
      ]
    },
    {
      _id: 2,
      name: "Standard",
      amount: 500,
      terms: "mo",
      tag: "Recomended",
      click: () => { },
      features: [
        { name: "Personal Access", availability: true },
        { name: "Unlimited overview", availability: true },
        { name: "Unlimited task creation and edit", availability: true },
        { name: "Direct messeging to the coach", availability: true },
        { name: "Upto 10 areas of interest", availability: true },
        { name: "Self-Learning system", availability: true },
        { name: "Google task integration", availability: true },
      ]
    },
    {
      _id: 3,
      name: "Premium",
      amount: 750,
      terms: "mo",
      tag: "",
      click: () => { },
      features: [
        { name: "Personal Access", availability: true },
        { name: "Unlimited overview", availability: true },
        { name: "Unlimited task creation and edit", availability: true },
        { name: "Direct messeging to the coach", availability: true },
        { name: "All areas of interest", availability: true },
        { name: "Self-Learning system", availability: true },
        { name: "Google task integration", availability: true },
      ]
    },
  ]

  useEffect(() => {
    async function settingsData() {
      try {        
        const result = await apiService("setting/detail",'get');
        if (result.status === 200) {
          setSettings(result?.data);
        } else {
          console.log("settings details message", result.message);
        }
      } catch (error) {
        console.log("Error settings details catch", error.message);
      }
    }
    settingsData();
  },[])

  return (
    <>

      <DefaultHeader data={menuData} />
      <ScrollSpy scrollThrottle={100} useBoxMethod={false} updateHistoryStack={false} activeClass="text-pistachio-500">
        <Outlet />
      </ScrollSpy>
      <DefaultFooter data={footerData} settings={settings}/>
      <Modal
        isOpen={isOpenSignUp}
        onCancel={() => setIsOpenSignUp(false)}
        title={""}
        secondaryTitle={""}
        showClose={false}
      >
      <Signup 
      click={() => { 
        setIsOpenSignUp(false); 
        //setIsOpenPricing(true) 
      }}
      onClose={() => setIsOpenSignUp(false) }
      />
      </Modal>
      <Modal
        isStatic={true}
        isOpen={isOpenPricing}
        showClose={false}
        onCancel={() => setIsOpenPricing(false)}
        size={"xl5"}
        containerClass={"!bg-slate-100"}
      >
        <div className="py-8">
          <PlanPricingTable data={pricing} />
        </div>
      </Modal>
    </>
  );
};

export default DefaultLayout;