import React from 'react';

const FaqSkeleton = () => {
  return (    
    <section className="relative overflow-hidden w-full py-20 bg-slate-100 z-0 min-h-[800px]" id="faq">
      <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="grid grid-cols-5 gap-10">
          <div className="col-span-2">
            <div className="relative h-[500px] rounded-3xl shadow-2xl overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full z-[-1]">
                {/* <Image src={data.image} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover object-center'} /> */}
              </div>
              <div className="absolute top-0 left-0 w-full h-full z-0 bg-cyprus-500 opacity-60"></div>
              <div className="absolute top-0 left-0 w-full h-full z-[1] py-10 px-10 space-y-4 flex flex-col justify-end">
                <h2 className="animate-pulse bg-slate-300 font-MPlusRounded1c text-3xl sm:text-4xl xl:text-5xl text-white"></h2>
                <div className="animate-pulse bg-slate-300 text-sm xl:text-base text-white/75 space-y-4"></div>
                <div className="animate-pulse bg-slate-300 text-sm xl:text-base text-white/75 space-y-4"></div>
                <div className="animate-pulse bg-slate-300 text-sm xl:text-base text-white/75 space-y-4"></div>
                <div className="animate-pulse bg-slate-300 text-sm xl:text-base text-white/75 space-y-4"></div>
                
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="space-y-4">
              <div className="relative bg-white rounded-lg overflow-hidden shadow border border-slate-100">
                <button
                  type="button"
                  className="w-full text-left flex items-center py-4 px-5 border-none bg-transparent"
                >
                  <div className="w-full flex-grow flex-shrink">
                    <h4 className="animate-pulse bg-slate-200 text-base sm:text-base text-cyprus-500 font-semibold !leading-tight"></h4>
                  </div>
                  <div className="w-8 h-8 flex-grow-0 flex-shrink-0 ml-auto rounded-md text-slate-400 transition-all duration-200 overflow-hidden flex items-center justify-center hover:bg-slate-100 hover:text-slate-800">
                  <p className="h-1 animate-pulse bg-slate-300"></p>
                  </div>
                </button>
                <div className={"bg-white"}>
                  <div className="animate-pulse bg-slate-200 text-sm xl:text-[15px] text-slate-600 space-y-4">
                    <p className="h-1 animate-pulse bg-slate-300"></p>
                    <p className="h-1 animate-pulse bg-slate-300"></p>
                    <p className="h-1 animate-pulse bg-slate-300"></p>
                    <p className="h-1 animate-pulse bg-slate-300"></p>
                    <p className="h-1 animate-pulse bg-slate-300"></p>
                  </div>
                </div>
              </div>
              <div className="relative bg-white rounded-lg overflow-hidden shadow border border-slate-100">
                <button
                  type="button"
                  className="w-full text-left flex items-center py-4 px-5 border-none bg-transparent"
                >
                  <div className="w-full flex-grow flex-shrink">
                    <h4 className="animate-pulse bg-slate-200 text-base sm:text-base text-cyprus-500 font-semibold !leading-tight"></h4>
                  </div>
                  <div className="w-8 h-8 flex-grow-0 flex-shrink-0 ml-auto rounded-md text-slate-400 transition-all duration-200 overflow-hidden flex items-center justify-center hover:bg-slate-100 hover:text-slate-800">
                  </div>
                </button>
                <div className={"px-5 border-slate-100 overflow-hidden transition-all duration-200 max-h-0 opacity-0 invisible"}>
                  <div className="h-1 animate-pulse bg-slate-200 text-sm xl:text-[15px] text-slate-600 space-y-4"></div>
                  <div className="h-1 animate-pulse bg-slate-200 text-sm xl:text-[15px] text-slate-600 space-y-4"></div>
                  <div className="h-1 animate-pulse bg-slate-200 text-sm xl:text-[15px] text-slate-600 space-y-4"></div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSkeleton;