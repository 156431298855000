import React,{useState,useEffect} from 'react';
import Input from "../form/Input";
import Button from "../form/Button";
import Select from "../form/Select";
import SearchWithDropdown from "../form/SearchWithDropdown";

import { apiService } from '../../services/api';
import toastr from "toastr";

const Signup = ({ click,onClose=() => {} }) => { 
  const dropdownData = [
    { name: '+91' },
    { name: '+1' },
    { name: '+44' },
    { name: '+93' },
    { name: '+355' },
    { name: '+213' },
    { name: '+1684' },
    { name: '+376' },
    { name: '+244' },
    { name: '+1264' },
    { name: '+1268' },
    { name: '+54' },
  ]
  const [timezoneList,setTimezoneList] = useState([]);
  const [isFirstNameValidate,setIsFirstNameValidate] = useState(false);
  const [isLastNameValidate,setIsLastNameValidate] = useState(false);
  const [isEmailValidate,setIsEmailValidate] = useState(false);
  const [isPasswordValidate,setIsPasswordValidate] = useState(false);
  const [isPasswordRegex,setIsPasswordRegex] = useState(false);
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] =  useState('');
  const [email,setEmail] = useState('');
  const [coachId,setCoachId] = useState('');
  const [password,setPassword] = useState('');
  const [timezoneName,setTimezoneName] = useState('');
  const [timezoneOffset,setTimezoneOffset] = useState();
  const [timezoneOffsetName,setTimezoneOffsetName] = useState('');
  const [gender,setGender] = useState(1);
  const [isCheck,setIsCheck] = useState(1);
  const [isSignup,setIsSignup] = useState(false);
  const regex =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSignup(true);
    if(firstName){setIsFirstNameValidate(false);}else{setIsFirstNameValidate(true);}
    if(lastName){setIsLastNameValidate(false);}else{setIsLastNameValidate(true);}
    if(email){setIsEmailValidate(false);}else{setIsEmailValidate(true);}
    if(password){setIsPasswordValidate(false);}else{setIsPasswordValidate(true);}
    if(password && !regex.test(password)){setIsPasswordRegex(true);}else{setIsPasswordRegex(false);}
    if(timezoneName){setTimezoneName(false);}else{setTimezoneName(true);}
    if(firstName && lastName && email && password){
      try {
        const userData = await apiService("signup", {
          firstName : firstName,
          lastName : lastName,
          email: email,
          coachId:coachId,        
          password: password,
          timezone_name : timezoneName,
          timezone_offset : timezoneOffset,
          timezone_offset_name : timezoneOffsetName,
          gender: gender
        },'POST');
        if (userData.status && userData.status === 200) {
          setIsSignup(false);
          setIsCheck(2);        
          //toastr.success(userData.message);
        } else { 
          toastr.error(userData.message);
          setIsSignup(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsSignup(false);
      }
    }else{
      setIsSignup(false);
    }    
  }

  useEffect(() => {
    async function timezoneData() {
      try {
        const result = await apiService("country/timezones?limit=250&offset=0",'get');
        if (result.data) {
          setTimezoneList(
            result.data.map((item)=>{
              return {
                _id : item?.country?._id,
                name : item?.zoneName,
                offset:item?.gmtOffset,
                gmtOffset:item?.gmtOffsetName,
              }
            })
          );
        } else {
          console.log("timezone list message", result.message);
        }
      } catch (error) {
        console.log("Error timezone list catch", error.message);
      }
    }
    timezoneData();
  },[])

  useEffect(() => {
    setTimezoneName('America/Denver');
    setTimezoneOffset(-25000);
    setTimezoneOffsetName("UTC-07:00");
  },[])

  return (
    <>
      <div className="py-4">
        {
            (isCheck && isCheck === 1) && 
            <div className="space-y-1 mb-6">
              <h1 className="text-2xl xl:text-3xl font-MPlusRounded1c font-bold text-cyprus-500">Signup</h1>
              <div className="text-slate-500 text-sm">Create an account using your credentials.</div>
            </div>
        }
        <form>
          {
            (isCheck && isCheck === 1)?
            <>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-3">
                <Input
                  label={'First Name'}
                  labelClasses={''}
                  inputType={'text'}
                  inputValue={firstName}
                  setInput={(val) => {
                    setFirstName(val);
                    if(val){
                      setIsFirstNameValidate(false);
                    }else{
                      setIsFirstNameValidate(true);
                    }
                  }}
                  functions={onSubmit}
                  inputPlaceholder={''}
                  errorText={(!firstName && isFirstNameValidate)?'Enter First Name':''}
                  errorType={(!firstName && isFirstNameValidate)?'danger':''}
                />
              </div>
              <div className="mb-3">
                <Input
                  label={'Last Name'}
                  labelClasses={''}
                  inputType={'text'}
                  inputValue={lastName}
                  setInput={(val) => {
                    setLastName(val);
                    if(val){
                      setIsLastNameValidate(false);
                    }else{
                      setIsLastNameValidate(true);
                    }
                  }}
                  functions={onSubmit}
                  inputPlaceholder={''}
                  errorText={(!lastName && isLastNameValidate)?'Enter Last Name':''}
                  errorType={(!lastName && isLastNameValidate)?'danger':''}
                />
              </div>
            </div>
            <div className="mb-3">
              <Input
                label={'Email'}
                labelClasses={''}
                inputType={'email'}
                inputValue={email}
                setInput={(val) => {
                  setEmail(val);
                  if(val){
                    setIsEmailValidate(false);
                  }else{
                    setIsEmailValidate(true);
                  }
                }}
                functions={onSubmit}
                inputPlaceholder={''}
                errorText={(!email && isEmailValidate)?'Enter Email':''}
                errorType={(!email && isEmailValidate)?'danger':''}
              />
            </div>
            <div className="mb-3">
              <Input
                label={"Password"}
                labelClasses={"!text-xs"}
                inputType={"password"}
                inputValue={password}
                setInput={(val) => {
                  setPassword(val);
                  if(val){
                    if(regex.test(val)){
                      setIsPasswordRegex(false);
                    }else{
                      setIsPasswordRegex(true);
                    }
                    setIsPasswordValidate(false);
                  }else{
                    setIsPasswordValidate(true);
                  }
                }}
                functions={onSubmit}
                inputPlaceholder={''}
                errorText={(!password && isPasswordValidate)?'Enter Password':isPasswordRegex?'Password should be minimum 8 character (1 letter 1 capital letter 1 small letter and 1 special character':''}
                errorType={(!password && isPasswordValidate)?'danger':isPasswordRegex?'danger':''}
              />
            </div>   
            <div className="grid grid-cols-2 gap-4">
              <div className='mb-3'>
                <SearchWithDropdown
                  label={'Timezone'}
                  labelClasses={'!text-xs'}
                  xPlacement={'left'} 
                  selectedValue={timezoneName+' '+timezoneOffset} 
                  dropdownClass={'max-h-40'} 
                  dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                  dropdownData={
                      [
                          { _id: '', name: 'Select Timezone',value:''} ,
                          ...timezoneList.map((item) => ({
                            _id: item?._id, 
                            name: item?.name+' ('+item?.gmtOffset+')',
                            value:item?.gmtOffset,
                            gmtOffset :item?.gmtOffset,
                            zonename : item?.name,
                            offset : item?.offset
                          }))
                      ]
                  }
                  getSelectedValue={(e) => {
                    setTimezoneName(e.zonename);
                    setTimezoneOffset(e.offset);
                    setTimezoneOffsetName(e.gmtOffset);
                  }}
                />
                {/* <Select 
                  label={'Timezone'}
                  labelClasses={'!text-xs'}
                  xPlacement={'left'} 
                  selectedValue={timezoneName+' '+timezoneOffset} 
                  dropdownClass={'max-h-40'} 
                  dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                  dropdownData={
                      [
                          { _id: '', name: 'Select Timezone',value:''} ,
                          ...timezoneList.map((item) => ({
                            _id: item?._id, 
                            name: item?.name+' ('+item?.gmtOffset+')',
                            value:item?.gmtOffset,
                            gmtOffset :item?.gmtOffset,
                            zonename : item?.name,
                            offset : item?.offset
                          }))
                      ]
                  }
                  getSelectedValue={(e) => {
                    setTimezoneName(e.zonename);
                    setTimezoneOffset(e.offset);
                    setTimezoneOffsetName(e.gmtOffset);
                  }}
                /> */}
              </div>
              <div className='mb-3'>
                <Select 
                  label={'Gender'}
                  labelClasses={'!text-xs'}
                  xPlacement={'left'} 
                  selectedValue={gender} 
                  dropdownClass={'max-h-40'} 
                  dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                  dropdownData={
                    [
                      { _id: '', name: 'Select Gender',value:''} ,
                      { _id: 1, name: 'Male',value:1},
                      { _id: 2, name: 'Female',value:2}
                    ]
                  }
                  getSelectedValue={(e) => setGender(e._id)}
                />
              </div>
            </div>     
            <div className="mb-3">
              <Button
                buttonType={"button"}
                buttonClasses={'!w-full'}
                buttonFunction={onSubmit}
                buttonLabel={"Signup"}
                buttonIcon={(isSignup)?"fa-light fa-spinner fa-spin":""}
                buttonIconPosition={(isSignup)?"left":""}
                buttonLabelClasses={"uppercase font-semibold"}
                buttonEffect={"filled"}
                buttonHasLink={false}
                buttonDisabled={isSignup}
              />
            </div>
            </>:
            <div class="relative p-4 text-center dark:bg-gray-800 sm:p-5">              
              <div class="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                  <svg aria-hidden="true" class="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span class="sr-only">Success</span>
              </div>
              <p class="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Successfully account create.</p>
              <Button
                buttonType={"button"}
                buttonClasses={'!w-full'}
                buttonFunction={() => onClose()}
                buttonLabel={"Continue"}
                buttonLabelClasses={"uppercase font-semibold"}
                buttonEffect={"filled"}
              />
          </div>
          }
          
        </form>
      </div>
    </>
  );
};

export default Signup;