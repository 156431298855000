import React from 'react';
import PlanPricingTable from "../partials/PlanPricingTable";

const PlanPricing = ({ data }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-20 bg-gradient-to-tr from-cyprus-500 to-pistachio-500 z-0" id="plans">
        <div className="absolute top-0 left-0 w-full h-full z-[-1] bg-texture bg-[length:30px_30px] bg-repeat opacity-10"></div>
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-3/12">
              <div className="space-y-8">
                <h2 className="font-MPlusRounded1c text-3xl sm:text-4xl xl:text-5xl text-white font-medium" dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                <div className="text-sm xl:text-base text-white/75 font-light space-y-4" dangerouslySetInnerHTML={{ __html: data.description }}></div>
              </div>
            </div>
            <div className="w-8/12">
              {data.pricing?.length > 0 &&
                <PlanPricingTable data={data.pricing} />
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlanPricing;