import React,{useState,useEffect} from 'react';
import Input from "../form/Input";
import TextAreaAutoSize from "../form/TextareaAutoSize";
import Button from "../form/Button";
import toastr from "toastr";
import { apiService } from '../../services/api';

const ContactForm = () => {
  const [isFirstNameValidate,setIsFirstNameValidate] = useState(false);
  const [isLastNameValidate,setIsLastNameValidate] = useState(false);
  const [isEmailValidate,setIsEmailValidate] = useState(false);
  const [isMessageValidate,setIsMessageValidate] = useState(false);
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] =  useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [message,setMessage] = useState('');
  const [isSend,setIsSend] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSend(true);
    if(firstName){setIsFirstNameValidate(false);}else{setIsFirstNameValidate(true);}
    if(lastName){setIsLastNameValidate(false);}else{setIsLastNameValidate(true);}
    if(email){setIsEmailValidate(false);}else{setIsEmailValidate(true);}
    if(message){setIsMessageValidate(false);}else{setIsMessageValidate(true);}
    if(firstName && lastName && email && message){
      try {
        const userData = await apiService("enquiry/add", {
          firstName : firstName,
          lastName : lastName,
          email: email,
          phone:phone,        
          message: message,
        },'POST');
        if (userData.status && (userData.status === 200 || userData.status === 201)) {
          setIsSend(false);    
          setFirstName('');
          setLastName('');
          setEmail('');
          setPhone('');
          setMessage('');
          toastr.success(userData.message);
        } else { 
          toastr.error(userData.message);
          setIsSend(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsSend(false);
      }
    }else{
      setIsSend(false);
    }    
  }

  return (
    <>
      <form>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-3">
            <Input
              label={'First Name'}
              labelClasses={''}
              inputType={'text'}
              inputValue={firstName}
              setInput={(val) => {
                setFirstName(val);
                if(val){
                  setIsFirstNameValidate(false);
                }else{
                  setIsFirstNameValidate(true);
                }
              }}
              inputPlaceholder={''}
              errorText={(!firstName && isFirstNameValidate)?'Enter First Name':''}
              errorType={(!firstName && isFirstNameValidate)?'danger':''}
            />
          </div>
          <div className="mb-3">
            <Input
              label={'Last Name'}
              labelClasses={''}
              inputType={'text'}
              inputPlaceholder={''}
              inputValue={lastName}
              setInput={(val) => {
                setLastName(val);
                if(val){
                  setIsLastNameValidate(false);
                }else{
                  setIsLastNameValidate(true);
                }
              }}
              errorText={(!lastName && isLastNameValidate)?'Enter Last Name':''}
              errorType={(!lastName && isLastNameValidate)?'danger':''}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-3">
            <Input
              label={'Phone'}
              labelClasses={''}
              inputType={'tel'}
              inputPlaceholder={''}
              inputValue={phone}
              setInput={(val) => {
                setPhone(val);
              }}
            />
          </div>
          <div className="mb-3">
            <Input
              label={'Email'}
              labelClasses={''}
              inputType={'email'}
              inputPlaceholder={''}
              inputValue={email}
              setInput={(val) => {
                setEmail(val);
                if(val){
                  setIsEmailValidate(false);
                }else{
                  setIsEmailValidate(true);
                }
              }}
              errorText={(!email && isEmailValidate)?'Enter Email':''}
              errorType={(!email && isEmailValidate)?'danger':''}
            />
          </div>
        </div>
        <div className="mb-3">
          <TextAreaAutoSize
            label={"Message"}
            inputValue={message}
            setTextarea={(val) => {
              setMessage(val)
              if(val){
                setIsMessageValidate(false);
              }else{
                setIsMessageValidate(true);
              }
            }}            
            errorText={(!message && isMessageValidate)?'Enter Message':''}
            errorType={(!message && isMessageValidate)?'error':''}
          />
        </div>
        <div className="mb-3">
          <Button
            buttonType={"button"}
            buttonClasses={'!w-full'}
            buttonFunction={onSubmit}
            buttonLabel={"Submit"}
            buttonLabelClasses={"uppercase font-semibold !text-sm"}
            buttonEffect={"filled"}
            buttonIcon={(isSend)?"fa-light fa-spinner fa-spin":""}
            buttonIconPosition={(isSend)?"left":""}
            buttonHasLink={false}
            buttonDisabled={isSend}
          />
        </div>
      </form>
    </>
  );
};

export default ContactForm;